<template>
  <v-card>
    <v-card-title>
      {{ "Roles" }}
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      <v-spacer></v-spacer>
      <v-btn class="ma-2" outlined color="indigo" @click="add()">
        {{ "Add New" }}
      </v-btn>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="browse"
      :search="search"
      :loading="loading"
      loading-text="LOADING.... PLEASE WAIT"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.index="{ item }">
        {{ item.index }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <span v-if="item.name === `${$IYA.ACL.ROLE_DEV}` || item.name === `${$IYA.ACL.ROLE_ADMIN}`"></span>
        <span v-else>
          <v-btn depressed text color="primary" @click="read(item.id)">
            <v-icon left dark>
              {{ icons.mdiPencil }}
            </v-icon>
          </v-btn>
          <v-btn depressed text color="error" @click="trash(item.id)">
            <v-icon left dark>
              {{ icons.mdiDelete }}
            </v-icon>
          </v-btn>
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { mdiPencil, mdiDelete } from "@mdi/js";
import Request from "@/core/api/request";
import permission from "@/directive/permission"; // Permission directive (v-permission)

export default {
  name: "RoleBrowse",
  directives: { permission },

  data() {
    return {
      loading: true,
      search: "",
      browse: [],
      headers: [
        { text: "NO.", value: "index", sortable: true },
        { text: "Name", value: "name", sortable: false },
        { text: "Guard Name", value: "guard_name", sortable: false },
        { text: "Action.", value: "actions", sortable: false },
      ],
      icons: {
        mdiPencil,
        mdiDelete,
      },
    };
  },

  computed: {
    perms() {
      return {
        read: this.$IYA.ACL.PERMISSION_USER_READ,
        add: this.$IYA.ACL.PERMISSION_USER_ADD,
        remove: this.$IYA.ACL.PERMISSION_USER_REMOVE,
      };
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      const { data, status } = await Request.get(`${this.$IYA.URI.ROLES__BROWSE}`);
      if (status !== this.$IYA.HTTP.OK) {
        this.loading = false;

        return;
      }
      if (data.data.length < 1) {
        this.loading = false;

        return;
      }
      data.data.forEach((item, i) => {
        item.index = i + 1;
      });
      this.browse = data.data;
      this.loading = false;
    },

    read(param) {
      this.$router.push({ name: "Role-Read", params: { id: param } });
    },
    add() {
      this.$router.push({ name: "Role-Add" });
    },

    trash(id) {
      this.$swal({
        title: `Are you sure?`,
        text: `You can't revert your action`,
        type: `warning`,
        showCancelButton: true,
        confirmButtonText: `Yes Delete it!`,
        cancelButtonText: `No, Keep it!`,
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(result => {
        if (result.value) {
          this.destroy(id);
        } else {
          this.$swal(`Cancelled`, `Your records are still intact`, `info`);
        }
      });
    },

    async destroy(id) {
      await Request.post(`${this.$IYA.URI.ROLES__DELETE}`, { id: id }).then(response => {
        if (response.status !== this.$IYA.HTTP.OK) {
          this.$swal(`Failed`, `Failed to remove record!`, `error`);
          return;
        }
        this.$swal(`Deleted`, `You successfully deleted this record`, `success`);
        this.initialize();
      });
    },
  },
};
</script>
